<template>
<div v-if="!postArrayMobile.error">
	<div class="EntitySingle">
		<Header />
    <div class="px-6">
      <h5 class="font-heading text-h5 text-sepiaBlack dark:text-white">{{ postArrayMobile.title }}</h5>
      <p class="font-heading text-subS text-halfColonialWhite dark:text-white font-normal mt-4">{{ postArrayMobile.era_date }}</p>
      <div class="text-center mt-12 dark:text-white">
		  <Gallery />
	  </div>
      <div id="buttonSett" class="text-center py-6">
				<Button :text="$t('entity.moreBtn')" buttonType="primary" fullWidth @click="showEntity()"/>
				<Button href="/mobile/qrCode" :text="$t('entity.backBtn')" buttonType="secondary" fullWidth />
			</div>
			<div id="descriptionPart" class="pb-12 hidden">
				<p class="font-body text-bodyM text-sepiaBlack dark:text-white">{{ $t('entity.description') }}</p>
      	<p class="font-body text-bodyM text-sepiaBlack dark:text-white mt-8" v-html="postArrayMobile.description"></p>
			</div>
    </div>
  </div>
</div>
<div v-else>
  <h1>ERROR! ELEMENT NOT FOUND!</h1>
</div>
</template>

<script>

import Header from "@/components/mobile/Header.vue";
import Button from '@/components/Button.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	name: 'EntitySingle',
	components: {
		Header,
		Button,
		Gallery
	},
	props: {
		content: {
		type: Object,
		require: true,
		default: () => {}
		}
	},
	computed: {
		postArrayMobile() {
			return {
				title: this.content?.title,
				text: this.content?.sport_content,
				src: this.content?.img,
				alt: this.content?.alt,
				description: this.content?.description,
				link: window.location.pathname + '/' + this.content?.id,
				error: this.content?.error,
				entity_id: this.content?.id,
				era_date: this.content?.era_date
			}
		}
  },
	methods: {
		showEntity() {
			document.querySelector("#descriptionPart").classList.remove("hidden");
			document.querySelector("#buttonSett").classList.add("hidden");
		}
	}
}
</script>

<style scoped>
img {
	margin: 0 auto;
}
</style>