<template>
    <div>
        <EntitySingle :content="element"/>
        <MobileNavigation />
    </div>
</template>

<script>

import EntitySingle from "@/components/mobile/EntitySingle.vue";
import MobileNavigation from "@/components/mobile/Navigation.vue";

export default {
    name: 'MobileDetail',
    components: {
        EntitySingle,
        MobileNavigation
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            element: {},
            id: this.$route.params.id,
        }
    },
    mounted() {
        this.getElementById();
    },
    methods: {
        getElementById() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/mobile/`+this.id, {}).then(response => {
                this.element = response.data;
                if (!response.data.error) {
                    this.saveToTimeline();
                }
            }).catch( (error) => {
                console.log(error);
            });
        },
        saveToTimeline() {
            // alert(this.$route.params.id + ` saved to timeline`);
            const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
            this.axios.post(this.baseURL + `/wp-json/wp/v2/timelinebyuser`, {entity_id: this.id, user_id: authUser.data.user.id, language: 'en'}, {})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    // console.log(response);
                } else {
                    console.log(response);
                }
            }).catch( (error) => {
                console.log(error);
            });
        }
    }
}
</script>
